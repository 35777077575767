import React from "react";
// import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
// import { GiRibbonMedal, GiHammerNails } from "react-icons/gi";
// import { AiOutlineTeam } from "react-icons/ai";
// import { IoMdHammer } from "react-icons/io";
function HeroVideo({ urlVideo, title, texts, bgImg, bgImg2 }) {
    // const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex lg:justify-center justify-start">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="z-[6] flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[80px] md:text-start text-center text-white">
                    <div className="w-full text-center lg:text-start p-4 lg:w-[60%] ">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[3em]">{title}</h1>
                        <p className="">{texts}</p>
                        <ButtonContent btnStyle="three" />
                    </div>
                    <div className="relative w-[40%]">
                        <img src={bgImg} alt="img1" className="hidden lg:flex w-[300px] h-auto border-white border-[8px] mt-10" />
                        <img src={bgImg2} alt="img1" className="absolute hidden lg:flex w-[300px] h-[400px] -top-10 -right-0 border-black border-[8px] -z-[2]" />

                    </div>
                </div>
                <div className="w-[30%] h-full bg2 md:absolute md:block right-0 -z-[0] hidden ">

                </div>
            </div>

        </div>
    );
}
export default HeroVideo;